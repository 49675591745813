@font-face {
  font-family: 'Lavanderia';
  src: url('./font/LavanderiaRegular.otf') format('opentype');
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

.app {
  text-align: center;
  background-color: rgb(244, 247, 240);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #142100;
  padding: 0 2rem;
}

h1,
h2,
div.quote {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

h1 {
  font-size: 3.5rem;
  letter-spacing: -1px;
}

h2 {
  font-size: 2rem;
  margin-top: -3rem;
}

a {
  color: #142100;
  text-decoration: none;
  display: block;
}

a:hover {
  color: #437000;
}

h1,
h2 {
  font-family: 'Lavanderia';
}

div.quote {
  font-family: 'Lavanderia';
  font-size: 2rem;
  margin-bottom: 2rem;
}

@media screen and (min-width: 900px) {
  .app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    font-size: 10rem;
    letter-spacing: -3px;
    margin-top: -5rem;
  }

  h2 {
    font-size: 6.25rem;
    margin-top: -9rem;
    letter-spacing: -2px;
  }

  p {
    font-size: 2rem;
  }

  div.quote {
    position: absolute;
    bottom: 2rem;
    font-size: 3rem;
    margin: 0;
  }
}